import React, {Fragment} from 'react'
import {useHistory} from 'react-router-dom'
import {Typography} from 'antd'
import {get} from 'lodash'
import icon_intro from '../styles/images/icon_intro.png'
import icon_detail from '../styles/images/icon_detail.png'
import icon_buy from '../styles/images/icon_buy.png'
import icon_cert from '../styles/images/icon_cert.png'

const {Paragraph} = Typography

const TraceHeader = ({info = {}, traces = [], btnClick}) => {
  const history = useHistory()

  const openUrl = e => {
    const id = e.currentTarget.dataset.id
    const link_obj = info[id] || {}
    const url = link_obj.url || ''
    window.open(url)
  }

  const btn_list = [
    {label: '企业概况', icon: icon_intro, id: 'owner', className: 'intro'},
    {label: '产品详情', icon: icon_detail, id: 'detail_link', className: 'detail'},
    {label: '认证报告', icon: icon_cert, id: 'cert_link', className: 'cert'},
    {label: '在线购买', icon: icon_buy, id: 'buy_link', className: 'buy'},
  ]

  return <Fragment>
    <div className="trace-header card-container">
      <div className="top-bar">
        <div className="title">{info.name}</div>
        <div className="subTitle">{get(info, 'owner.name')}</div>
      </div>
      <div className="info">
        <Paragraph ellipsis={{rows: 5, expandable: true}}>{info.description}</Paragraph>
      </div>
      <div className="btn-group">
        {
          btn_list.map((i, index) => info[i.id] ? 
            <div key={index} className="btn-item" onClick={openUrl} data-id={i.id}>
              <img src={i.icon} alt=""/>
              <span className={i.className}>{i.label}</span>
            </div> : null
          )
        }
      </div>
    </div>

    <div className="trace-code card-container">
      <div className="label">映物码:</div>
      <div className="value">{info.code}</div>
      <div className="btn-group">
        <div className="button" onClick={() => {
          history.push(`/block?code=${info.code}`, {
            description: info.description,
            traces: traces
          })
        }}>区块链验证</div>
        <div className="button" onClick={btnClick}>溯源记录</div>
      </div>
    </div>
  </Fragment>
}

export default TraceHeader
