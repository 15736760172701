import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import {hot} from 'react-hot-loader/root'
import Page from './Page'
import {ConfigProvider} from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import './styles/less/index.less'

const RHLPage = process.env.NODE_ENV === "development" ? hot(Page) : Page

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <RHLPage />
  </ConfigProvider>, 
  document.getElementById('root'))


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
