import React from 'react'

const TraceDescription = ({data={}, className}) => {
  const list = data.list || []
  return (
    <div className={`trace-des card-container ${className || ''}`}>
      <div className="title-bar">
        <div className="trace-des-title">
          {data.icon && <img className="icon" src={data.icon} alt=""/>}
          <span className="title">{data.title}</span>
        </div>
        {data.extra}
      </div>
      
      {
        list.map((i, index) => {
          const status = i.status || {}
          const color = status.code === 1 ? 'light-blue' : status.code === 16 ? 'green' : 'blue'
          return (
            <div key={index} className="trace-des-item">
              <div className="label">{i.label}</div>：
              <div className="value">
                {i.value}
                {status.value && <span className={`status ${color}`}>{status.value}</span>}
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default TraceDescription