export function getQueryUrl(key) {
  const url = window.location.href
  if(url.indexOf("?") !== -1){
    const param = url.split("?")[1].split("&")
    const formatParams = {}
    for(let i=0; i<param.length; i += 1){
      formatParams[param[i].split("=")[0]] = param[i].split("=")[1]
    }
    return formatParams[key]
  }
}

function add0(m) { return m<10?'0'+m:m }
// 将毫秒数转为时间日期
export function formatTime(shijianchuo, options = {}) {
  if(!shijianchuo) {
    return '--'
  }
  
  const {hasYear = true, hasTime = true, hasSecond = true, s = '-'} = options

  //shijianchuo是整数，否则要parseInt转换
  let time = new Date(Number(shijianchuo))
  let y = time.getFullYear()
  let m = time.getMonth()+1
  let d = time.getDate()
  let h = time.getHours()
  let mm = time.getMinutes()
  let ss = time.getSeconds()
  // let s = time.getSeconds()+1
  // return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s)
  return `${hasYear ? y+s : ''}${add0(m)}${s}${add0(d)}${hasTime ? ` ${add0(h)}:${add0(mm)}${hasSecond ? ':' + add0(ss) : ''}` : ''}`
}