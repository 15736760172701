import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from './pages/home_page'
import Block from './pages/block_page'
import SearchPage from './pages/search_page'
import NotFound from "./pages/not_found"

export default () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route exact path='/search' component={SearchPage}/>
      <Route exact path="/block" component={Block}/>
      <Route component={NotFound}/>
    </Switch>
  </Router>
)
