import React, {useState} from 'react'
import {SearchBar, Flex, WhiteSpace, Button} from 'antd-mobile'
import {useHistory} from 'react-router'

const SearchPage = () => {
  const [values, setValues] = useState(undefined)
  const history = useHistory()
  const onSubmit = v => {
    if(!v) return 
    history.push(`/?code=${v}`)
  }
  return (
    <div className='search-page'>
      <div className='card-container'>
        <Flex 
          direction='column'
          style={{width: '100%'}}>
          <Flex.Item>
            <div className='search-page-title'>溯源查询</div>
          </Flex.Item>
          <WhiteSpace size='xl'/>
          <Flex.Item style={{width: '100%'}}>
            <SearchBar 
              onChange={v => setValues(v)}
              placeholder='请输入映物码查询'
              onSubmit={onSubmit}
            />
          </Flex.Item>
          <WhiteSpace size='xl'/>
          <Flex.Item style={{width: '95%'}}>
            <Button
              type='primary' 
              onClick={() => onSubmit(values)}
              size='large'>
              查询
            </Button>
          </Flex.Item>
        </Flex>
      </div>
    </div>
  )
}

export default SearchPage