import React from 'react'

// chain动效点
const dots = [
  {left: '35px', top: '38px'}, 
  {left: '30px', top: '95px'}, 
  {left: '10px', top: '110px'}, 
  {left: '57px', top: '168px'}, 
  {left: '82px', top: '157px'},
  {left: '75px', top: '87px'}, 
  {left: '87px', top: '46px'}, 
  {left: '146px', top: '31px'}, 
  {left: '164px', top: '111px'}, 
  {left: '172px', top: '171px'}
]

const TraceChain = ({data = {}}) => {
  return (
    <div className="trace-chain">
      <div className="logo"/>
      <div className="chain-box">
        <div className="chain-container container">
          <div className="content">
            <span className="label">当前节点</span>
            <span className="value">{data.node_count || '--'}</span>
          </div>
          <div className="content">
            <span className="label">区块高度</span>
            <span className="value">{data.block_height || '--'}</span>
          </div>
          <div className="content">
            <span className="label">链上用户</span>
            <span className="value">{data.user_count || '--'}</span>
          </div>
        </div>

        <div className="chain-bg">
          <div className="ball" />
          <div className="circle" />
          {
            dots.map((i, index) => {
              return (
                <div className="water-container" key={index} style={i}>
                  <div className="water">
                    <div className="center"/>
                    <div className="point point1"/>
                    <div className="point point2"/>
                    <div className="point point3"/>
                    <div className="point point4"/>
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className="dot-container">
          <div className="dot-wrap">
            <div className="dot dot-1"/> 
            <div className="dot dot-2"/> 
            <div className="dot dot-3"/> 
            <div className="dot dot-4"/> 
            <div className="dot dot-5"/> 
            <div className="dot dot-6"/> 
            <div className="dot dot-7"/> 
            <div className="dot dot-8"/>
            <div className="dot dot-9"/>
            <div className="dot dot-10"/>
          </div>
          <div className="dot-box"><div className="box"/></div>
        </div>
      </div>

    </div>
  )
}

export default TraceChain