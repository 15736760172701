import React, {useState, useEffect, Fragment} from 'react'
import Helmet from 'react-helmet'
import {Icon} from 'antd-mobile'
import {TraceChain, TraceDescription, Transfer, TraceModal, Empty} from '../components'
import icon_traceability from '../styles/images/icon_traceability.png'
import icon_det from '../styles/images/icon_det.png'
import icon_chain from '../styles/images/icon_chain.png'
import {useLocation} from 'react-router-dom'
import api, {handleResponse} from '../api'
import {get} from 'lodash'
import {formatTime, getQueryUrl} from '../utils'
import {Typography} from 'antd'
import icon_info from '../styles/images/icon_info.png'

const {Paragraph} = Typography

const BlockPage = () => {
  const {state = {}} = useLocation()
  const [status, setStatus] = useState(null)
  const [traces, setTraces] = useState(state.traces || [])
  const [trx_info, setTrxInfo] = useState({})
  const [spatio_info, setSpatioInfo] = useState({})
  const [anchor_info, setAnchorInfo] = useState({})
  const [network_stat, setNetworkStat] = useState({})

  const [visible, setVisible] = useState(false)

  const [description, setDescription] = useState(state.description || '--')

  useEffect(() => {
    window.scrollTo(0, 0)
    getTraceInfo()
    !state.traces && getSpatioTrace()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTraceInfo = async () => {
    try {
      const response = await api.getTraceInfo({code: getQueryUrl('code')})
      handleResponse({
        response,
        resolve: () => {
          setStatus(1)
          const resData = response.data.data || {}
          setTrxInfo(resData.trx_info || {})
          setSpatioInfo(resData.spatio_info || {})
          setAnchorInfo(resData.anchor_info || {})
          setNetworkStat(resData.network_stat || {})
        },
        reject: () => {
          setStatus(0)
        }
      })
    } catch (error) {
      setStatus(0)
    }
  }

  const formatTraces = (data = []) => {
    let res = []

    data.forEach(i => {
      let arr = [{...i, operator: get(i, 'owner.name')}]

      i.paths && i.paths.forEach(n => {
        arr.push(n)
      })
      res.push({...i, paths: arr})
    })
    setTraces(res)
  }

  const getSpatioTrace = async () => {
    try {
      const response = await api.getSpatioTrace(getQueryUrl('code'))
      handleResponse({
        response,
        resolve: () => {
          const resData = response.data.data || {}
          formatTraces(resData.traces)
          setDescription(get(resData, 'info.description'))
        },
      })
    } catch (error) {
    }
  }

  const trx_status = trx_info.status || {}
  const statusComponent = (
    <div style={{color: '#41BB94', display: 'flex', alignItems: 'center'}}>
      {trx_status.code === 1 ? <Icon type="check-circle" size="xxs" style={{marginRight: '10px'}}/> : ''}
      {trx_status.value || '--'}
    </div>
  )

  const trx = {
    title: '交易详情',
    icon: icon_det,
    list: [
      {label: '交易hash', value: trx_info.trx_hash || '--'},
      {label: '交易时间', value: formatTime(trx_info.trx_time) || '--'},
      {label: '区块确认', value: formatTime(trx_info.confirm_time) || '--'},
      {label: '区块高度', value: trx_info.block_height || '--'},
      {label: '交易状态', value: statusComponent},
    ]
  }

  const product = {
    title: '产品信息',
    icon: icon_traceability,
    list: [
      {label: '产品名称', value: spatio_info.item_name || '--'},
      {label: '企业名', value: spatio_info.person || '--'},
      {label: '记录坐标', value: spatio_info.coordinate || '--'},
      {label: '映物码', value: spatio_info.code || '--'},
      {label: '商品简介', value: <Paragraph ellipsis={{rows: 3, expandable: true}}>{description || '--'}</Paragraph>},
    ]
  }

  const chain = {
    icon: icon_chain,
    title: '公链校验',
    list: [
      {
        label: '最新状态', 
        value: formatTime(anchor_info.anchor_time), 
        status: anchor_info.status
      },
      {label: '公链hash', value: anchor_info.trx_hash || '--'},
      {label: '解密密码', value: anchor_info.decrypt_password || '--'},
    ],
    extra: <div className="extra" onClick={() => setVisible(true)}>
      <img src={icon_info} alt=""/>
    </div> 
  }

  const Page = () => (
    <div className="trace">
      <TraceChain data={network_stat}/>
      <TraceDescription data={trx}/>
      <TraceDescription data={product}/>
      <TraceDescription data={chain}/>
      <Transfer data={traces}/>
      {visible && <TraceModal changeVisible={() => setVisible(false)}/>}
    </div>
  )

  const makePage = () => {
    switch(status) {
    case 0:
      return <Empty/>
    case 1:
      return <Page/>
    default:
      return null
    }
  }

  return <Fragment>
    <Helmet title="时空区块链"/>
    {makePage()}
  </Fragment>
}

export default BlockPage