import React, {useState, Fragment, useRef} from 'react'
import api, {handleResponse} from '../api'

const TraceModal = ({changeVisible, code}) => {
  const [current, setCurrent] = useState(0)
  const [data, setData] = useState({})

  return (
    <div className="modal-page">
      <div className="modal-box">
        <div className="icon-close" onClick={changeVisible}/>
        {
          current === 0 
            ? <VerifyForm code={code} setData={setData} setCurrent={setCurrent} />
            : <VerifyData data={data} changeVisible={changeVisible}/>
        }
      </div>
      <style>
        {`
          body {
            overflow: hidden;
          }
        `}
      </style>
    </div>
  )
}

export default TraceModal

const VerifyForm = ({code, setData, setCurrent}) => {
  const length = 6
  const inputEl = useRef(null)
  const [value, setValue] = useState('')

  const onButtonClick = () => {
    inputEl.current.focus()
  }

  const focusChange = e => {
    let inputValue = e.target.value
    if(inputValue.length > length) return
    if(!/^\d*$/.test(inputValue)) return
    setValue(inputValue)
  }

  const submit = async () => {
    if(value.length === 6) {
      try {
        const response = await api.postTraceVerify({rand: value}, code)
        handleResponse({
          response,
          resolve: () => {
            const resData = response.data.data || {}
            setData(resData)
            setCurrent(1)
          },
        })
      } catch (error) {
        
      }
    }
  }
  return (
    <Fragment>
      <h3>请输入标签上6位验证码</h3>
      <div className='verify-form'>
        {
          Array(length).fill('').map((i, index) => {
            return (
              <div key={index} className='verify-form-item'
                style={{borderBottom: (value.length === 0 && index === 0) || (value.length <= index - 1) ? {} : ''}}
                onClick={onButtonClick} >
                {(value.length === index) && <div className="verify-form-item-cursor" />}
                {(value.length >= index + 1) && <div className="verify-form-item-text">{value[index]}</div>}
              </div>
            )
          })
        }
        <input value={value} className='ipt' autoFocus ref={inputEl} onChange={focusChange} />
      </div>
      <div className={`modal-btn ${value.length === 6 ? '' : 'modal-btn-disabled'}`} onClick={submit}>查验</div>
    </Fragment>
  )
}

const VerifyData = ({data = {}, changeVisible}) => {
  const count = data.verify_count || 0
  const histories = (data.histories || [])
  const firstTime = histories[histories.length - 1].time

  return <Fragment>
    <div className="verify">
      <div className="verify-header">
        <div>查询次数：{count}次</div>
        <div>首查时间：{firstTime}</div>
      </div>
      <div className="verify-table">
        <table>
          <thead>
            <tr>
              <th>查询次数</th>
              <th>查询时间</th>
              <th>查询地点</th>
            </tr>
          </thead>
          <tbody>
            {histories.map((i, index) => (
              <tr key={index}>
                <td>{count - index}</td>
                <td>{i.time || '--'}</td>
                <td>{i.city || '--'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <div className="modal-btn" onClick={changeVisible}>关闭</div>
  </Fragment>
}