import React, {Fragment, useState, useEffect} from 'react'
import {TraceMap} from './index'
import icon_transfer from '../styles/images/icon_transfer.png'

const Transfer = ({data = [], mark = [], updatePage}) => {
  const [expend, setExpend] = useState([])

  useEffect(() => {
    let arr = []
    data.forEach((i, index) => {
      arr.push(index)
    })
    setExpend(arr)
  }, [data])
  
  const onClick = (index) => {
    const isExpend = expend.findIndex(i => i === index)
    let arr = [...expend]
    if(isExpend !== -1) {
      arr.splice(isExpend, 1)
    } else {
      arr.push(index)
    }
    setExpend(arr)
  }

  const openNewPage = (e, code) => {
    e.stopPropagation()
    updatePage(code)
  }

  return (
    <div className="trace-transfer card-container trace-des">
      <div className="title-bar">
        <div className="trace-des-title">
          <img className="icon" src={icon_transfer} alt=""/>
          <span className="title">流通链</span>
        </div>
      </div>
      {mark.length ? <TraceMap mark={mark}/> : null}
      <div className="transfer">
        {
          data.map((i, index) => (
            <Fragment key={index}>
              <div className="code" data-code={i.code} onClick={() => onClick(index)}>
                <div className="content">
                  <div>{index === 0 ? '映物码' : '父级映物码'}：</div>
                  <div className="text">{i.code}</div>
                </div>
                {i.extra && <div className="extra" onClick={e => openNewPage(e, i.code)}/>}
              </div>
              {expend.findIndex(i => i === index) !== -1 && <Steps data={i.paths} isOne={index === 0}/>}
            </Fragment>
          ))
        }
      </div>
    </div>
  )
}

export default Transfer

const Steps = ({data = [], isOne}) => {
  return (
    <div className="steps">
      <div className="steps-line"/>
      <div className="steps-container">
        <div className="steps-item">
          {
            data.map((i, index) => {
              const location = i.location || {}
              return (
                <Fragment key={index}>
                  <div className="steps-title"> 
                    <div className="steps-icon"/>
                    <span className="time">{i.time}</span>
                    {isOne && index === 0 && <div className="new">最新</div>}
                  </div>
                  <div className="steps-content">
                    <div className="item">
                      <div className="icon icon-address" />
                      <div className="content">
                        <span className="label">地理坐标</span>：{i.no || '--'}<span className="dot">（{location.lng || '--'}，{location.lat || '--'}）</span>                
                      </div>
                    </div>
                    <div className="item">
                      <div className="icon icon-recorder" />
                      <div className="content">
                        <span className="label">记录人</span>：
                        {i.operator || '--'}
                      </div>   
                    </div>
                  </div>
                </Fragment>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}