import React, {useState, useEffect} from 'react'
import ReactQMap from './QMap'
import {Spin} from 'antd'

const TraceMap = ({mark = []}) => {
  const [center, setCenter] = useState({longitude: null, latitude: null})

  useEffect(() => {
    const item = mark[0] || {}
    setCenter({longitude: item.longitude || null, latitude: item.latitude || null})
  }, [mark])

  return (
    <div className="trace-map">
      {
        (center.latitude && center.longitude) 
          ? <ReactQMap 
            center={center}
            mark={mark}
            initialOptions={{zoomControl: true}} 
            apiKey="xxxxxx-xxxxx-xxxxx-xxxxxx"
            style={{height: 500}}
          /> 
          : <Spin/>
      }
    </div>
  )
}

export default TraceMap