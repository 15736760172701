/* eslint-disable no-unused-vars */
import {fetchData} from "./resource"

const webApi = process.env.REACT_APP_API_URL
export default {
  getSpatioTrace: (id) => fetchData('get', `${webApi}/spatio/trace/${id}`),
  getTraceInfo: (params) => fetchData('get', `${webApi}/trace`, {params}),
  postTraceVerify: (data, id) => fetchData('post', `${webApi}/spatio/verify/${id}`, {data, transform: true})
}

export const handleResponse = ({response, resolve=() => {}, reject = () => {}}) => {
  if(response.data.code === 0) {
    resolve(response.data.data)
  } else {
    reject()
  }
}

