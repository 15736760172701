import React, {useEffect, useState, Fragment} from 'react'
import Helmet from 'react-helmet'
import {TraceHeader, Transfer, VerifyModal, Empty, Footer} from '../components'
import api, {handleResponse} from '../api'
import {getQueryUrl} from '../utils'
import {get} from 'lodash'
import {useHistory, useLocation} from 'react-router-dom'

const Home = () => {
  const history = useHistory()
  const location = useLocation()
  const [status, setStatus] = useState(null)
  const [data, setData] = useState([])

  const [traces, setTraces] = useState([])
  const [mark, setMark] = useState([])

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    formatTraces(data.traces)
  }, [data])

  useEffect(() => {
    window.scrollTo(0, 0)
    getSpatioTrace()
  }, [location])

  const updatePage = (code) => {
    history.push(`/?code=${code}`)
  }

  const formatTraces = (data = []) => {
    let res = []
    let mark = []

    data.forEach(i => {
      let arr = [{...i, operator: get(i, 'owner.name')}]
      let mark_arr = [{
        latitude: i.location.lat,
        longitude: i.location.lng,
        title: i.no
      }]
      i.paths && i.paths.forEach(n => {
        arr.push(n)
        mark_arr.push({
          latitude: n.location.lat,
          longitude: n.location.lng,
          title: n.no
        })
      })
      res.push({...i, paths: arr, extra: i.code !== getQueryUrl('code')})
      mark = mark.concat(mark_arr)
    })
    setTraces(res)
    setMark(mark)
  }

  const getSpatioTrace = async () => {
    try {
      const response = await api.getSpatioTrace(getQueryUrl('code'))
      handleResponse({
        response,
        resolve: () => {
          const resData = response.data.data || {}
          setData(resData)
          setStatus(1)
        },
        reject: () => {
          setStatus(0)
        }
      })
    } catch (error) {
      setStatus(0)
    }
  }
  
  return <Fragment>
    <Helmet title="映物溯源"/>
    {status === 0 && <Empty/>}
    {status === 1 && <div className="trace">
      <TraceHeader info={data.info} traces={traces} btnClick={() => setVisible(true)}/>
      <Transfer data={traces} mark={mark} updatePage={updatePage}/>
      <Footer/>
      {visible && <VerifyModal changeVisible={() => setVisible(false)} code={getQueryUrl('code')}/>}
    </div>}
  </Fragment>
}

export default Home