import React from 'react'
import icon_safe from '../styles/images/icon_safe.png'

const TraceModal = ({changeVisible}) => {
  return (
    <div className="modal-page">
      <div className="modal-box">
        <img src={icon_safe} alt=""/>
        <div className="modal-title">公有链校验</div>
        <div className="modal-content">蓝海数链区块链网络将溯源数据利用加密技术锚定至公有链网络进行交叉对比校验，保证数据未被篡改，增强数据公信力的同时，也保证了溯源信息的隐私安全。</div>
        <div className="modal-btn" onClick={changeVisible}>知道了</div>
      </div>
      <style>
        {`
          body {
            overflow: hidden;
          }
        `}
      </style>
    </div>
  )
}

export default TraceModal